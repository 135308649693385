
import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import MenuAllergensModal from "./menu_allergens_modal"

function MenuItemModal(props) {
    const { product, show, handleClose, language, direction, currency } = props;
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };
    return (
        <>
        <Modal
            show={show} onHide={handleClose}
            centered
            dir={direction}
        >
            <Modal.Body className='p-0'>
                <Card className="border-0">
                    <Card.Img variant="top" src={product.Images[0]['Image1']} />
                    <Card.Body>
                        <Card.Title className="text-center">{ product.Names[0].DigitalName }</Card.Title>
                        <Card.Text className="text-center px-4 lh-sm">{ product.Names[0].LongDescription}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <div className="row justify-content-between pe-3">
                            <div className="col-5 pe-0">
                                <span className='card-title card-price'>{language === "AR" ? product.Prices[0].Price+" "+currency[1] : product.Prices[0].Price+" "+currency[0]}</span>
                            </div>
                            <div className={language === "AR" ? "col-7 px-0 text-start ps-2" : "col-7 px-0 text-end"} onClick={() => handleOpenModal()}>
                                {product.Names[0].Calories !== "" ? (<span className='text-cal'>{language === "AR" ? product.Names[0].Calories + " س.ح" : product.Names[0].Calories + " Cal"}</span>) : ("")}
                                {
                                    product.Allergens?.map((item, index) => {
                                        return <img className="p-icon" src={"../assets/" + item.Allergen.Icon} width="25" height="25" alt=''></img>
                                    })
                                }
                            </div>
                        </div>
                    </Card.Footer>
                </Card>
            </Modal.Body>
        </Modal>
        <MenuAllergensModal show={showModal} handleClose={handleCloseModal}/>
        </>
    );
}

export default MenuItemModal;