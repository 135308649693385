
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
function MenuAllergensModal(props) {
    const { show, handleClose } = props;
    return (
        <Modal
            show={show} onHide={handleClose}
            centered
        >
            <Modal.Body className='p-0'>
                <Card className="border-0">
                    <Card.Img variant="top" src="https://awjftp.blob.core.windows.net/awjcontents/digimenuAllergens/Awani_Allergens.png" />
                </Card>
            </Modal.Body>
        </Modal>
    );
}

export default MenuAllergensModal;